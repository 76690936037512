<template>
  <div class="col-12 col-lg-6">
    <div class="bg-light-primary px-6 py-8 rounded-xl mb-7">
      <p class="text-primary">Bugünün Üretimi</p>
      <highcharts :options="chartOptions" v-show="graphReady"></highcharts>
      <div class="graph-loading" v-show="!graphReady">
        <p class="text-primary">Grafik Yükleniyor</p>
        <i class="fas fa-spinner fa-pulse text-primary ml-3"></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.graph-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
  }
  i {
    font-size: 1.5rem;
  }
}
p {
  font-weight: 600;
  font-size: 2rem;
}
</style>

<script>
import { wattConvertor } from "@/assets/js/functions/wattConvertor";
import "@/assets/js/functions/highcharts.settings";
export default {
  name: "product_today_info_graph",
  props:['seriesData','graphReady'],
  data() {
    return {
      chartOptions: {},
    };
  },
  methods: {
    createChart() {
      this.chartOptions = {
        chart: {
          type: "pie",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          backgroundColor: "#E1F0FF",
        },
        series: {
          name: "Üretim",
          colorByPoint: true,
          data: this.seriesData,
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            maxSize: 320,
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              color: "#3699FF",
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              style: {
                textOutline: false,
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            var tooltip =
              `<span style="color:` +
              this.color +
              `"><b>` +
              this.series.name +
              `:</b> </span><span>` +
              wattConvertor(this.y, "h") +
              `</span>`;
            return tooltip;
          },
        },
      };
    },
  },
  watch:{
    seriesData(){
      this.createChart();
    }
  }
};
</script>

<template>
  <div class="col-12 col-lg-6">
    <div class="px-6 py-8 rounded-xl mb-7" :class="'bg-light-' + color">
      <div class="d-inline-block float-right status-card">
        <div class="product-info">
          <p :class="'text-' + color" v-if="data != ''">{{ data }}</p>
          <i
            :class="'fas fa-spinner fa-pulse text-' + color + ' fa-3x mr-3'"
            v-if="data == ''"
          ></i>
        </div>
      </div>
      <span :class="'svg-icon svg-icon-6x svg-icon-' + color + ' d-block my-2'">
        <inline-svg :src="require(`/public/media/svg/icons/${svgURL}`)" />
      </span>
      <p class="button-text" :class="'text-' + color + ' mt-2'">
        {{ buttonText }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button-text {
  font-weight: 600;
  font-size: 2rem;
}
.product-info {
  p {
    font-weight: 600;
    font-size: 4rem;
  }
}
</style>

<script>
export default {
  name: "product_info_card",
  props: ["color", "data", "svgURL", "buttonText"],
};
</script>

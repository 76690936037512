<template>
  <div>
    <div class="card card-custom bg-gray-100 card-stretch gutter-b mb-7">
      <!--begin::Header-->
      <div class="card-header border-0 bg-danger py-5">
        <h1 class="text-white">Üretim Bilgileri</h1>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body p-0 position-relative overflow-hidden">
        <!--begin::Stats-->
        <div class="card-spacer">
          <!--begin::Row-->
          <div class="row m-0 mb-3">
            <product-info-card
              button-text="Bugünün Üretimi"
              color="primary"
              :data="getStationTodayExport"
              svgURL="Shopping/Chart-pie.svg"
            ></product-info-card>
            <product-info-card
              button-text="Toplam Üretim"
              color="danger"
              :data="getAllStationsTotal"
              svgURL="Electric/Highvoltage.svg"
            ></product-info-card>
          </div>
          <div class="row m-0">
            <product-today-info-graph
              :series-data="todaySeries"
              :graph-ready="todayGraphReady"
            ></product-today-info-graph>
            <product-all-info-graph
              :series-data="allSeries"
              :graph-ready="allGraphReady"
            ></product-all-info-graph>
          </div>
          <!--end::Row-->
        </div>
        <!--end::Stats-->
      </div>
      <!--end::Body-->
      <!--end::Mixed Widget 1-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-header {
  h1 {
    font-weight: 600;
  }
}
</style>

<script>
import {
  GET_STATION_TODAY_EXPORT,
  GET_ALL_STATIONS_TOTAL,
  GET_STATIONS,
  GET_STATION_TOTAL,
} from "@/core/services/store/stations.module";
import { mapGetters } from "vuex";
import ProductInfoCard from "./components/ProductInfoCard.vue";
import ProductTodayInfoGraph from "./components/ProductTodayInfoGraph.vue";
import ProductAllInfoGraph from "./components/ProductAllInfoGraph.vue";

export default {
  name: "Main",
  components: {
    ProductInfoCard,
    ProductTodayInfoGraph,
    ProductAllInfoGraph,
  },
  data() {
    return {
      todaySeries: [],
      allSeries: [],
      todayGraphReady: false,
      allGraphReady: false,
    };
  },
  computed: {
    ...mapGetters([
      "getStations",
      "getStationsTableLoading",
      "getStationTodayExport",
      "getAllStationsTotal",
      "getUserRole",
    ]),
  },
  created() {
    this.$store.dispatch(GET_STATION_TODAY_EXPORT);
    this.$store.dispatch(GET_ALL_STATIONS_TOTAL);
    this.$store.dispatch(GET_STATIONS).then((response) => {
      let temp_length = response.length;
      let process = 0;
      response.forEach((item) => {
        this.todaySeries.push({
          name: item.name,
          y: item.dailyTotal,
        });
        this.$store
          .dispatch(GET_STATION_TOTAL, { id: item.id })
          .then((response) => {
            this.allSeries.push({
              name: item.name,
              y: response,
            });
            process++;
            if (process == temp_length) {
              this.allGraphReady = true;
            }
          });
      });
      this.todayGraphReady = true;
    });
  },
};
</script>
